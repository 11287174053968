import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { HomeComponent } from './home/home.component';
import { RegisterComponent } from './register/register.component';
import { ValidatorsInputErrorModule } from './_helpers/validators-input-error/validators-input-error.module';
import { ConfirmEmailComponent } from './confirm-email/confirm-email.component';
import { ConfirmedEmailComponent } from './confirmed-email/confirmed-email.component';
import { PriceComponent } from './price/price.component';

const routes: Routes = [
  { path: '', component: HomeComponent },
  { path: 'register', component: RegisterComponent },
  { path: 'confirm-email/:id/:token/:subdomain/:companyId/:email', component: ConfirmEmailComponent },
  {path: 'confirm-email/:token', component: ConfirmedEmailComponent},
  { path: 'price', component: PriceComponent },
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { scrollPositionRestoration: 'enabled' })],
  exports: [RouterModule, ValidatorsInputErrorModule]
})
export class AppRoutingModule { }
