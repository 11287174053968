import { Component, OnInit, Input } from '@angular/core';
import {CookieService} from 'angular2-cookie/core';
import { UserService } from 'src/app/_services/user.service';
import { ValidatorsInputErrorComponent } from '../validators-input-error/validators-input-error.component';

@Component({
  selector: 'app-modal-question',
  templateUrl: './modal-question.component.html',
  styleUrls: ['./modal-question.component.css']
})
export class ModalQuestionComponent implements OnInit {

  constructor(private _cookieService:CookieService, private userService: UserService, private validatorInputErrorComponent: ValidatorsInputErrorComponent) { }

 @Input() public showModalQuestion: boolean = false;

  ngOnInit() {
    //this._cookieService.remove('me_user_type');
    //console.log(JSON.parse(this._cookieService.get('me_user_type')));
   if(!this._cookieService.get('me_user_type')){
      this.showModalQuestion = true;
    }
  }

  respondQuestion(option:string){
   
    this.showModalQuestion = false;

    this.userService.defineType({type: option}).then((response:any) =>{
      this._cookieService.put('me_user_type', '{"id": "'+response.id+'", "option": "'+option+'"}');
    }).catch( error => {
      this.validatorInputErrorComponent.loadErrors(error);
    });
  }

}
