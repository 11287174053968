import { Component, OnInit, Input, Injectable } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { FormGroup, FormBuilder, Validators, FormControl, FormArray } from '@angular/forms';


@Injectable({
  providedIn: 'root'
})

@Component({
  selector: 'app-validators-input-error',
  templateUrl: './validators-input-error.component.html',
  styleUrls: ['./validators-input-error.component.css']
})
export class ValidatorsInputErrorComponent implements OnInit {

  @Input() public label: string;
  @Input() public field: string;
  @Input() public form: boolean = false;

  @Input() public showErrors = false;

  @Input() public registerForm: any;

  constructor( private toastr: ToastrService ) { }

  ngOnInit() {

  }

  loadErrors(errorResponse, registerForm=null){

   let error: any = errorResponse.error;
   let invalid_param_error = 0;
    if(error.errors && error.errors.length > 0){
      for(let i=0; i < error.errors.length; i++){
        let err = error.errors[i];
        
        if(err.code == 'INVALID_PARAM'){
          if(registerForm){
            let path = this.navigateToControlForm(err.param, registerForm);

            if(path == false){
              this.toastr.warning(err.msg, 'Erro!');
            }
            else if(eval(path)){

              let ePath = eval(path);
              invalid_param_error = invalid_param_error+1;
             
              ePath.setErrors({'nomatch': true, 'msg': err.msg});
             
            }else{
              this.toastr.warning(err.msg, 'Erro!');
            }
            
          }
        }else{
          this.toastr.warning(err.msg, 'Erro!',{timeOut: 20000});
        }
      }

      if(invalid_param_error > 0){
        let msg = (invalid_param_error == 1) ? 'Um campo foi digitado incorretamente.' : 'Alguns campos foram digitados incorretamente.'
        this.toastr.warning(msg, 'Erro!');
      }
    }
    else{
      this.toastr.warning("Ocorreu um erro ao se comunicar com o servidor.", 'Erro!');
    }
  }

  hiddenErrorMessage(typeError){
    if(this.showErrors == false){
      return true;
    }

    let registerForm = this.registerForm;
    let path = this.navigateToControlForm(this.field);

    if(path != false && eval(path+".errors") && eval(path+".errors."+typeError)){
      return false;
    }else{
      return true;
    }
  }

  showErrorMessage(typeError){
    let registerForm = this.registerForm;
    let path = this.navigateToControlForm(this.field);

    if(path != false && eval(path+".errors") && eval(path+".errors."+typeError)){
      return eval(path+".errors."+typeError);
    }else{
      return '';
    }
  }

/**
 * 
 * @param fieldForm Campo da mensagem de erro retornado da api: Exemplo sectors[0].ticketsDates[0].lotConfigs[0].price
 * Monta uma string para ser usada com eval
 * O script fará navegar até o local do formulário informado em fieldForm
 * Se o local nao existir retorna false
 */
  navigateToControlForm(fieldForm, registerForm=null){
    let split = fieldForm.split('.');
    var registerForm = (registerForm) ? registerForm : this.registerForm;
    //Campo simples
    if(split.length == 1){
      if(eval("registerForm.get('"+split[0]+"')")){
        return "registerForm.get('"+split[0]+"')";
      }else{
        return false;
      }
    }
    
    //Array com várias posições
    else{
      let formPath = 'registerForm';

      for(let i=0; i < split.length; i++){
        let field = split[i];
        //é um campo simples
        if(field.indexOf('[') == -1){
          formPath = formPath+".get('"+field+"')";  
          
        }
        
        //é um array
        else{
          let splitArray = field.split('[');
          let fieldName = splitArray[0];
          let position = splitArray[1].substr(0, splitArray[1].length-1);
          formPath = formPath+".get('"+fieldName+"')";

          if(eval(formPath)){
            formPath = formPath+".at("+position+")";
          }else{
            return false;
          }
        }
        
      }

      return formPath;
    }
  }

}
