import { Injectable } from '@angular/core';
import { environment } from "../../environments/environment";
import { HttpClient } from '@angular/common/http';
import Util from '../_helpers/util';

@Injectable({
  providedIn: 'root'
})

export class HomeService {

  private contact = 'landing/contact';
  
  constructor(private httpClient: HttpClient, private util: Util,) { }

  sendContact(params:any){
    let url = environment.apiUrl+this.contact;

    return this.httpClient.post(url, params).toPromise();
  }

}

