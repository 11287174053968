import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import AosHelper from '../_helpers/aos';
import { FormBuilder, Validators } from '@angular/forms';
import { HomeService } from '../_services/home.service';
import { ValidatorsInputErrorComponent } from '../_helpers/validators-input-error/validators-input-error.component';
import { Router } from '@angular/router';
import { sha256 } from 'js-sha256';

declare const window:any;
declare const AOS:any;
@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.css']
})
export class HomeComponent implements OnInit {

  @ViewChild('contactSuccess') contactSuccess:ElementRef;
  @ViewChild('closeContactSuccess') closeContactSuccess:ElementRef;
  

  constructor(private router: Router, private aos:AosHelper, private fb: FormBuilder, private homeService: HomeService, private valitorInputErrorComponent: ValidatorsInputErrorComponent) { }

  public contactForm = this.fb.group({
    name: ['', Validators.required],
    email: ['', Validators.required],
    phone: ['', Validators.required],
    subject: ['', Validators.required],
    message: ['', Validators.required],
    token: ['']
  });

  public contactFormShowError: boolean = false;
  public contactLoading: boolean = false;

  ngOnInit() {
    this.aos.init();
  }

  sendContact(){
    
    if(!this.contactForm.valid){
      this.contactFormShowError = true;
      return;
    }
    this.contactForm.get('token').setValue(sha256(this.contactForm.value.email+this.contactForm.value.phone+this.contactForm.value.name+this.contactForm.value.message.substr(1, 20)));
    this.contactLoading = true;
    this.homeService.sendContact(this.contactForm.value).then(response => {
      this.contactSuccess.nativeElement.click();
      this.contactForm.reset();
      this.contactLoading = false;
    }).catch(errorReponse => {
      this.valitorInputErrorComponent.loadErrors(errorReponse, this.contactForm);
      this.contactLoading = false;
    });
  }

  contactToRegister(){
    this.closeContactSuccess.nativeElement.click();
    this.router.navigate(['/register']);
  }


}
