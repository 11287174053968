import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { ToastrModule } from 'ngx-toastr';
import { NgxMaskModule} from 'ngx-mask-2';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { HomeComponent } from './home/home.component';
import { RegisterComponent } from './register/register.component';
import { HeaderComponent } from './layout/header/header.component';
import { FooterComponent } from './layout/footer/footer.component';
import { Footer2Component } from './layout/footer2/footer2.component';
import { ConfirmEmailComponent } from './confirm-email/confirm-email.component';
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { HeaderInterceptor } from './_interceptor/header.interceptor';
import { RouterModule } from '@angular/router';
import { ConfirmedEmailComponent } from './confirmed-email/confirmed-email.component';
import { ModalQuestionComponent } from './_helpers/modal-question/modal-question.component';
import { CookieService } from 'angular2-cookie/services/cookies.service';
import { PriceComponent } from './price/price.component';

@NgModule({
  declarations: [
    AppComponent,
    HomeComponent,
    RegisterComponent,
    HeaderComponent,
    FooterComponent,
    Footer2Component,
    ConfirmEmailComponent,
    ConfirmedEmailComponent,
    ModalQuestionComponent,
    PriceComponent
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    FormsModule,
    ReactiveFormsModule,
    AppRoutingModule,
    ToastrModule.forRoot(),
    NgxMaskModule.forRoot(),
    HttpClientModule,
    RouterModule
  ],
  providers: [
    CookieService
   // { provide: HTTP_INTERCEPTORS, useClass: HeaderInterceptor, multi: true,  }
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
