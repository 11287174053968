import { Component, OnInit, Inject } from '@angular/core';
import AosHelper from '../_helpers/aos';
import { RegisterService } from '../_services/register.service';
import { ActivatedRoute } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { ValidatorsInputErrorComponent } from '../_helpers/validators-input-error/validators-input-error.component';
import { DOCUMENT } from '@angular/common';

@Component({
  selector: 'app-confirmed-email',
  templateUrl: './confirmed-email.component.html',
  styleUrls: ['./confirmed-email.component.css']
})
export class ConfirmedEmailComponent implements OnInit {

  
  public loading:boolean = false;
  public confirmEmailNext: boolean = false;
  public confirmed: boolean = false;
  public linkRedirect;

  constructor( @Inject(DOCUMENT) private document: Document, private validatorInputErrorComponent: ValidatorsInputErrorComponent, private toastr:ToastrService, private registerService: RegisterService, private aos: AosHelper, private activatedRoute: ActivatedRoute) { }


  ngOnInit() {
    this.aos.init();

    this.loading = true;
    this.registerService.userConfirmEmail({token: this.activatedRoute.snapshot.paramMap.get('token')}).then((response:any) => {
      this.loading = false;
      if(!response.confirmed){
        this.toastr.warning("Entre em contato com o suporte.", "Erro ao confirmar e-mail");
        return;
      }

      this.confirmed = true;
      this.linkRedirect = "https://"+response.company.subdomain+".meuevento.com.br/admin/authentication/first/"+response.tokenForFirstLogin+"/"+response.company.id;

    }).catch(errorResponse => {
      this.loading = false;
      this.validatorInputErrorComponent.loadErrors(errorResponse);
    });
  }

  next(){
    this.loading = true;
    this.document.location.href = this.linkRedirect;
  }

}
