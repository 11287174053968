import { Component, OnInit, Inject } from '@angular/core';
import AosHelper from '../_helpers/aos';
import { RegisterService } from '../_services/register.service';
import { ValidatorsInputErrorComponent } from '../_helpers/validators-input-error/validators-input-error.component';
import { ActivatedRoute, Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { DOCUMENT } from '@angular/common';

@Component({
  selector: 'app-confirm-email',
  templateUrl: './confirm-email.component.html',
  styleUrls: ['./confirm-email.component.css']
})
export class ConfirmEmailComponent implements OnInit {

  public loading: boolean = false;
  public email: string = '';

  constructor(@Inject(DOCUMENT) private document: Document, private router: Router, private toastr: ToastrService,private activatedRoute: ActivatedRoute, private aos: AosHelper, private registerService: RegisterService, private validatorInputErrorComponent: ValidatorsInputErrorComponent){ }


  ngOnInit() {
    this.aos.init();
   this.email = this.activatedRoute.snapshot.paramMap.get('email');
  }

  next(){
    let id = this.activatedRoute.snapshot.paramMap.get('id');
    let token = this.activatedRoute.snapshot.paramMap.get('token');
    let companyId = this.activatedRoute.snapshot.paramMap.get('companyId');
    

    this.loading = true;
    this.registerService.userConfirmedEmail(id).then((response:{confirmed:boolean}) => {
      this.loading = false;
      if(!response.confirmed){
        this.toastr.warning("Acesse seu e-mail e clique no link que enviamos.", "Você ainda não confirmou o seu e-mail");
        return;
      }

      this.document.location.href = "https://"+this.activatedRoute.snapshot.paramMap.get('subdomain')+".meuevento.com.br/admin/authentication/first/"+token+"/"+companyId;
    }).catch(errorResponse => {
      this.loading = false;
      this.validatorInputErrorComponent.loadErrors(errorResponse);
    });
  }

}
