import { Injectable } from '@angular/core';

@Injectable({
    providedIn: 'root'
  })

export default class Util{

    build_query(obj) {
        var str = [];
        for (var p in obj){
          if(Array.isArray(obj[p])){
            for(let i=0; i < obj[p].length; i ++){
              str.push(encodeURIComponent(p+'[]') + "=" + encodeURIComponent(obj[p][i]));
            }
          }
          else if (obj.hasOwnProperty(p)) {
            str.push(encodeURIComponent(p) + "=" + encodeURIComponent(obj[p]));
          }
        }
        return str.join("&");
      }
}
