import { Injectable } from '@angular/core';
import { environment } from "../../environments/environment";
import { HttpClient } from '@angular/common/http';
import Util from '../_helpers/util';

@Injectable({
  providedIn: 'root'
})

export class UserService {

  private user_type_define = 'landing/userType';
  
  constructor(private httpClient: HttpClient, private util: Util,) { }

  defineType(params:any){
    let url = environment.apiUrl+this.user_type_define;

    return this.httpClient.post(url, params).toPromise();
  }
}

