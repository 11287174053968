import { NgModule, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ValidatorsInputErrorComponent } from './validators-input-error.component';
import { ToastrModule } from 'ngx-toastr';


@NgModule({
  imports: [
    CommonModule,
    ToastrModule.forRoot()
  ],
  declarations: [ValidatorsInputErrorComponent],
  exports: [ValidatorsInputErrorComponent],
  schemas:[],
  providers: [ValidatorsInputErrorComponent]
})
export class ValidatorsInputErrorModule { }
