import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { CookieService } from 'angular2-cookie';
import AosHelper from '../_helpers/aos';
import { sha256 } from 'js-sha256';
import { LandingService } from '../_services/landing.service';
import { ValidatorsInputErrorComponent } from '../_helpers/validators-input-error/validators-input-error.component';

@Component({
  selector: 'app-price',
  templateUrl: './price.component.html',
  styleUrls: ['./price.component.css']
})
export class PriceComponent implements OnInit {

  private form: FormGroup = this.fb.group({
    name: ['', Validators.required],
    surname: ['', Validators.required],
    email: ['', Validators.required],
    whatsapp: ['', Validators.required],
    eventsPerYear: ['', Validators.required],
    ticketsPerYear: ['', Validators.required],
    token: [''] //honeypot
  });

  public formShowError: boolean = false;

  public loadingForm: boolean = false;

  public success:boolean = false;

  public showModalQuestion:boolean = false;

  constructor(private valitorInputErrorComponent: ValidatorsInputErrorComponent,private landingService: LandingService,private aos: AosHelper, private fb: FormBuilder,private _cookieService:CookieService) { }

  ngOnInit() {
    this.aos.init();
  }

  submitForm(){

    if(!this.form.valid){
      this.formShowError = true;
      return;
    }

    if(!this._cookieService.get('me_user_type')){
      this.showModalQuestion = true;
      return;
    }

    let userType = JSON.parse(this._cookieService.get('me_user_type'));

    this.form.get('token').setValue(sha256(this.form.value.email+this.form.value.whatsapp.substr(1, 8)));
    

    let params = JSON.parse(JSON.stringify(this.form.value));
    params.userTypeId = userType.id;

    this.loadingForm = true;
    this.landingService.consultPrice(params).then(response => {
      this.loadingForm = false;

      this.success = true;
    }).catch(error =>{
      this.loadingForm = false;
      this.valitorInputErrorComponent.loadErrors(error, this.form);
    });
  }

}
