import { Injectable } from '@angular/core';
import { environment } from "../../environments/environment";
import { HttpClient } from '@angular/common/http';
import Util from '../_helpers/util';

@Injectable({
  providedIn: 'root'
})

export class RegisterService {

  private user_register = 'user/public';
  private user_confirm_email = 'user/public/confirmEmail';
  private user_confirmed_email = 'user/public/confirmedEmail';
  private company_register = 'company';
  
  constructor(private httpClient: HttpClient, private util: Util,) { }

  registerUser(params:any,apiLink?: any){
    let url = apiLink+this.user_register;
    return this.httpClient.post(url, params, {headers: apiLink}).toPromise();
  }

  registerCompany(params:any, apiLink?: any){
    let url = apiLink+this.company_register;

    return this.httpClient.post(url, params, {headers: apiLink}).toPromise();
  }

  userConfirmedEmail(id: string, apiLink?: any){
    let url = apiLink+this.user_confirmed_email+"?id="+id;
    
    return this.httpClient.get(url, {headers: apiLink}).toPromise();
  }

  userConfirmEmail(params: any, apiLink?: any){
    let url = apiLink+this.user_confirm_email;

    return this.httpClient.post(url, params, {headers: apiLink}).toPromise();
  }
}

