import { Injectable } from '@angular/core';

declare const window:any;
declare const AOS:any;

@Injectable({
    providedIn: 'root'
  })

export default class AosHelper{

    init(){
        if (!window.Cypress) AOS.init({
            useClassNames: true,
            initClassName: true,
            animatedClassName: 'animated',
            easing: 'ease-out-back',
            duration: 900,
            offset: 200,
            delay: 100,
          });
           
            AOS.init({
                offset: function () {
                  var maxWidth = 10;
                  return window.innerWidth < maxWidth;
                }
            });
    }

}