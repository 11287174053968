import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators, FormControl } from '@angular/forms';
import AosHelper from '../_helpers/aos';
import { RegisterService } from '../_services/register.service';
import { ValidatorsInputErrorComponent } from '../_helpers/validators-input-error/validators-input-error.component';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { sha256 } from 'js-sha256';
import { CookieService } from 'angular2-cookie';

@Component({
  selector: 'app-register',
  templateUrl: './register.component.html',
  styleUrls: ['./register.component.css']
})
export class RegisterComponent implements OnInit {

  public userFormShowError:boolean = false;
  public companyFormShowError:boolean = false;
  public apiLink: string = ""

public customPatterns = {'0': { pattern: new RegExp('\[a-z0-1\ \t\r\n\-_]')}};

  private userForm: FormGroup = this.fb.group({
    id: [''],
    tokenForFirstLogin: [''],
    name: ['', Validators.required],
    surname: ['', Validators.required],
    phones: this.fb.array([new FormControl('')]),
    email: ['', Validators.required],
    password: ['', Validators.required],
    permissions: [["Admin", "Reports", "FinancialUser"], Validators.required],
    token: [''] //honeypot
  });

  public companyForm: FormGroup = this.fb.group({
    id: [''],
    type: ['PJ', Validators.required],
    name: ['', Validators.required],
    cnpj: [''],
    cpf: [''],
    subdomain: ['', Validators.required],
    token: [''] //honeypot
  });

  public steps: number = 0;

  public loadingUser: boolean = false;
  public loadingCompany: boolean = false;

  public showModalQuestion:boolean = false;

  constructor(private _cookieService:CookieService, private toastr: ToastrService, private router: Router, private registerService: RegisterService, private aos: AosHelper,private fb: FormBuilder, private validatorsInputErrorComponent: ValidatorsInputErrorComponent) { }

  ngOnInit() {
    this.aos.init();
  }

  saveUser(){
    if(!this.userForm.valid){
      this.userFormShowError = true;
      return;
    }

    // if(!this._cookieService.get('me_user_type')){
    //   this.showModalQuestion = false;
    //   return;
    // }

    let userType = {};
    
    this.userForm.get('token').setValue(sha256(this.userForm.value.email+this.userForm.value.email.substr(1, 8)+this.userForm.value.password+this.userForm.value.password.substr(1, 8)));

    let params = JSON.parse(JSON.stringify(this.userForm.value));
    params.name = params.name+" "+params.surname;
    delete params.surname;
    params.userTypeId = "";
    

    this.loadingUser = true;
    this.registerService.registerUser(params,  this.apiLink).then((response:any) => {
      
      this.loadingUser = false;
      this.userForm.get('id').setValue(response.id);
      this.userForm.get('tokenForFirstLogin').setValue(response.tokenForFirstLogin);
      this.setStep(1);
    }).catch(errorResponse => {
      console.log("errorResponse----");
      console.log(errorResponse);
      this.loadingUser = false;
      this.userFormShowError = true;
      this.validatorsInputErrorComponent.loadErrors(errorResponse, this.userForm);
  
    });
  }

  chooseApi(eve) {
    if (eve.srcElement.options[eve.srcElement.options.selectedIndex].value == "") {
      document.getElementById("form").style.display = "none"
    } else {
      this.apiLink = eve.srcElement.options[eve.srcElement.options.selectedIndex].value
      document.getElementById("form").style.display = "block"
    }
  }

  setStep(step:number){
    if(!this.userForm.value.id){
      this.toastr.warning("Preencha os dados do usuário e clique em avançar.");
      return;
    }

    this.steps = step;

  }


  saveCompany(){
    if(!this.companyForm.valid){
      console.log(this.companyForm);
      this.companyFormShowError = true;
      return;
    }

    let companyName = this.companyForm.value.name.normalize('NFD').replace(/[\u0300-\u036f]/g, '');

    this.companyForm.get('token').setValue(sha256(this.companyForm.value.subdomain+this.companyForm.value.subdomain.substr(1, 8)+companyName+companyName.substr(1, 8)));

    let params = JSON.parse(JSON.stringify(this.companyForm.value));
    params.userId = this.userForm.value.id;
    params.domain = "";

    params.doc = (params.type == 'PJ') ? params.cnpj : params.cpf;

    this.loadingCompany = true;
    this.registerService.registerCompany(params, this.apiLink).then((response:any) => {
      this.router.navigate(["/confirm-email/"+this.userForm.value.id+"/"+this.userForm.value.tokenForFirstLogin+"/"+this.companyForm.value.subdomain+"/"+response.id+"/"+this.userForm.value.email]);
      this.loadingCompany = false;
    }).catch(errorResponse => {
      console.log("errorResponse-----------");
      console.log(errorResponse);
      this.loadingCompany = false;
      this.companyFormShowError = true;
      this.validatorsInputErrorComponent.loadErrors(errorResponse, this.companyForm);
      
    });
  }

}
